import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Qualidade</span> <br></br>
              no Processamento<br></br> avícola
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal11} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Fábio Nunes
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor e Engenheiro Químico
                      </div>
                      </div>
                    
                  </div>

                  {/* <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal9} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Marcos Sanchez-Plata
                      </div>
                    
                    <div className="titulo inter-light">
                     Professor Texas Tech University
                      </div>
                      </div>
                    
                  </div> */}
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        19 e 20 de Abril de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      400 € +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/EPA.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                    <div className="form">
                <a href="https://forms.gle/TgmCMNJbhLVE8kPz5" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Este curso é um ramo da "Escola de Processamento
Avícola" que consiste num programa avançado, oferecido
desde 2007, pela Texas Tech University em colaboração
com a University of Georgia, Athens y GA.
Esta formação é levada a cabo pelo Eng. Fábio Nunes
(consultor de matadouros e empresas avícolas na América
do Sul), oferecendo um intenso programa que abrange os
principais aspetos do processamento avícola.
                </div>
                <div className="logos">
                  
                {/* <img src={logo3} alt="" />
                <img src={logo4} alt="" /> */}

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          <br></br>Assim, a dinâmica do curso possibilita uma aprendizagem intensiva, organizada e coerente dos diferentes módulos abordados.
            </div>
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            +  Dia 1 - 19/04/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 09:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Apresentação do curso</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">09:30 - 10:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Uma perspetiva sobre as alterações do
mercado</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Importância da nutrição para a carcaça
(palestrante convidado)</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 12:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:00 - 13:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">qualidade da carcaça - Perdas económicas e a importância da
relação matadouro vs exploração</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 5</span> - <span class="txt">Maneio pré-abate e os seus reflexos para o rendimento e
segurança alimentar</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 16:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 17:30</span> | <span className="mod">Módulo 7</span> - <span class="txt">Receção, descarga, atordoamento e sangria - Pontos críticos</span></p>
                                </div>
                               
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 20/04/2023
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">09:00 - 09:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Receção</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">09:30 - 10:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Escaldão e depena - Preservação da
qualidade, rendimento e valor</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Integridade e uniformização da carcaça -
Importância para a qualidade e rendimento</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 12:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:00 - 13:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">visceração e a sua importância para a qualidade, rendimento
e inocuidade</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 5</span> - <span class="txt">Processo de refrigeração a ar - Vantagens e cuidados
e inocuidade</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 16:00</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 17:30</span> | <span className="mod">Módulo 6</span> - <span class="txt">Estratégias para melhorar o desempenho económico do matadouro
e inocuidade</span></p>
                                </div>
                  
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                              
                          
                             


                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/EPA.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/TgmCMNJbhLVE8kPz5" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        {/* <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal9} alt="" />

              </div>
              <div className="nome">
                Marcos Sanchez-Plata
              </div>
              <div className="titulo">
                  Professor Texas Tech University e Consultor
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
Professor na Tech Texas University
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Fundador e coordenador da Poultry School (Universidade da Georgia) e Meat School (Universidade Texas Tech).</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
Expert em desenvolvimento e promoção da segurança alimentar em carne de aves.</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Coordenador de ações internacionais em segurança alimentar e inspeção.
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Posicionado como Key global player em segurança alimentar, objetivando o aumento da disponibilidade, qualidade e acesso a alimentos seguros, nutritivos e sustentáveis para populações em risco em todo o mundo.</p>
                  </div>
              </div>
            </div>
        </div>
        </div> */}

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal11} alt="" />

              </div>
              <div className="nome">
                Fábio Nunes
              </div>
              <div className="titulo">
                  Engenheiro e Consultor
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> +30 anos de experiência na indústria avícola e consultor desde 2001.</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Expert em operações e gestão de matadouros, otimização e engenharia da cadeia de processamento, tecnologia, equipamentos</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>
                  Professor convidado da Poultry School - University of Georgia e Meat
School - Texas Tech University</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Desenvolveu projetos em mais de 20 países entre a América latina,
Ásia e Europa
</p>
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
      {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
  
)

export default wqmatadouros
